import { BalButton, BalHeading, BalText } from "@baloise/ds-react";
import { useMediaQuery } from "@lg-cfa/hooks";
import { MediaQuery } from "@lg-cfa/interfaces-enums";
import classnames from "classnames";
import { graphql, navigate } from "gatsby";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";

import {
    ContactFormTheme,
    ContactFormValue,
    Footer,
    Loading,
    NewContactForm,
} from "@modules/shared/components";
import { Application, LeadInfoKey } from "@modules/shared/enums";
import { modelDTO } from "@modules/shared/helpers";
import {
    useCreateLead,
    useResetFlow,
    useSitePaths,
} from "@modules/shared/hooks";
import { AppState, appState } from "@modules/shared/state";
import { PageProps } from "@modules/shared/types";
import { SimulatorLayout } from "@modules/simulator";

const ContactMePage: React.FC<PageProps> = ({ pageContext }) => {
    const { foundPage: page, ownPageObjects: allPages, language } = pageContext;
    const [appData, setAppData] = useRecoilState<AppState>(appState);
    const [loading, setLoading] = useState<boolean>(false);
    const { t } = useTranslation();
    const { getSitePath } = useSitePaths();
    const { resetFlow } = useResetFlow(
        allPages,
        language,
        Application.Simulator,
    );
    const createLead = useCreateLead();
    const isMobile = useMediaQuery(MediaQuery.Mobile);

    const initialFormValues: ContactFormValue = useMemo(
        () => ({
            ...appData.ContactMe,
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    const handleSubmit = async () => {
        setLoading(true);
        const redirect = allPages.find((p) => p.name === page?.navigation?.next)
            ?.paths[language];
        const errorRedirect = getSitePath("500");

        const dto = modelDTO(
            appData,
            language,
            LeadInfoKey.ContactMe,
            undefined,
            Application.Simulator,
        );

        const { success } = await createLead(dto, Application.Simulator);

        if (success) {
            resetFlow(false);
            navigate(redirect ?? "/");
        } else {
            navigate(errorRedirect);
        }

        setLoading(false);
    };

    const handleChange = (contactFormValue: ContactFormValue): void => {
        setAppData({
            ...appData,
            ContactMe: contactFormValue,
        });
    };

    return (
        <>
            {loading && (
                <Loading translationKey="simulator.contactme.loading" />
            )}
            <SimulatorLayout
                title={t("simulator.contactme.title")}
                page={page}
                language={language}
                allPages={allPages}
            >
                <main className="container is-compact mt-large flex flex-direction-column">
                    <div className="flex-1">
                        <div className="intro">
                            <BalHeading level="h1">
                                {t("simulator.contactme.title")}
                            </BalHeading>
                            <BalText>{t("simulator.contactme.intro")}</BalText>
                        </div>

                        <NewContactForm
                            theme={ContactFormTheme.Card}
                            showErrorMessageCard
                            errorMessageCardTitle={
                                t("all.errormessage.title") as string
                            }
                            errorMessageCardSubTitle={
                                t("all.errormessage.text") as string
                            }
                            application={Application.Simulator}
                            professionalUsage={
                                !!appData.BeforeWeStart?.professionalUsage
                            }
                            initialValue={initialFormValues}
                            onSubmit={handleSubmit}
                            onChange={handleChange}
                        >
                            <section
                                className={classnames(
                                    "mt-large flex gap-medium",
                                    {
                                        "flex-direction-column": isMobile,
                                    },
                                )}
                            >
                                <BalButton
                                    rounded
                                    expanded={isMobile}
                                    iconRight="caret-right"
                                    color="primary"
                                    elementType="submit"
                                    disabled={loading}
                                >
                                    {t(`simulator.contactme.nextstep`)}
                                </BalButton>
                            </section>
                        </NewContactForm>
                    </div>
                    <Footer />
                </main>
            </SimulatorLayout>
        </>
    );
};

export default ContactMePage;

export const pageQuery = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
